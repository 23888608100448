<template>
  <a-modal :title="title"
           :visible="modalShown"
           :mask-closable="true"
           :destroy-on-close="true"
           :width="800"
           dialog-class="import-excel-modal"
           @ok="closeModal"
           @cancel="closeModal">
    <a-table :columns="columns" :data-source="data" :pagination="false" :loading="loading" bordered
             row-key="id" class="table small-cell-table">
      <template slot="post_name_arr" slot-scope="text">
        <div v-for="(item, idx) of text" :key="idx">{{ item }}</div>
      </template>

      <template slot="footer">
        <Pagination v-if="pagination && pagination.total" :pagination="pagination"
                    class="pagination"
                    @change="onPageChange"
                    @showSizeChange="onSizeChange"></Pagination>
      </template>
    </a-table>
  </a-modal>
</template>

<script>
import {stickerActiveRecord} from '../../api/sticker';
import Common from '@/utils/Common';

import Pagination, {getPagination} from '@components/Pagination';

const columns = [
  {
    align: 'center',
    dataIndex: 'truename',
    key: 'truename',
    title: '姓名',
  },
  {
    align: 'center',
    dataIndex: 'post_name_arr',
    key: 'post_name_arr',
    title: '岗位',
    scopedSlots: {customRender: 'post_name_arr'},
  },
  {
    align: 'center',
    dataIndex: 'regional_org_name',
    key: 'regional_org_name',
    title: '大区',
  },
  {
    align: 'center',
    dataIndex: 'small_regional_org_name',
    key: 'small_regional_org_name',
    title: '小区',
  },
  {
    align: 'center',
    dataIndex: 'org_name',
    key: 'org_name',
    title: '经销商',
  },
  {
    align: 'center',
    dataIndex: 'num',
    key: 'num',
    title: '点击次数',
  },
];

export default {
  name: 'RecordModal',
  components: {Pagination},
  props: {
    item: {type: Object, default: () => new Object()},
  },
  computed: {
    title() {
      return (this.item?.title || '贴片') + '点击记录查询';
    },
  },
  data() {
    return {
      loading: false,
      columns: columns,
      data: [],

      pagination: getPagination(),

      modalShown: true,
    }
  },
  created() {
    this.getStickerActiveRecord(1, this.pagination.defaultPageSize);
  },
  destroyed() {
  },
  methods: {
    onPageChange(page, pageSize) {
      this.getStickerActiveRecord(page, pageSize);
    },
    onSizeChange(current, size) {
      this.getStickerActiveRecord(1, size);
    },

    async getStickerActiveRecord(page, pageSize) {
      page = page || 1;
      pageSize = pageSize || this.pagination.defaultPageSize;
      if (this.loading || !this.item?.id) {
        return;
      }
      this.loading = true;

      const data = await stickerActiveRecord(this.item.id, {
        page: page,
        page_size: pageSize,
      }).finally(() => this.loading = false);
      if (!data || data.error_code) {
        return;
      }
      this.data = data?.data || [];

      const pagination = data?.meta?.pagination || {};
      this.pagination = Common.updatePagination(this.pagination, pagination);
    },

    closeModal() {
      this.modalShown = false;
      this.$emit('close');
    },

  },
}
</script>

<style scoped lang="scss">
.pp-sticker-record {
  width: 100%;
}
</style>
