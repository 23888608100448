<template>
  <a-modal v-model="visible"
           :title="title"
           centered
           class="full-content-modal"
           :after-close="afterClosed"
           @cancel="close">
    <div class="full-content">
      <p v-for="(item, index) of content" :key="index">{{ item }}</p>
    </div>

    <template slot="footer">
      <a-button @click="close">关闭</a-button>
    </template>
  </a-modal>
</template>

<script>
export default {
  name: 'FullContentModal',
  data() {
    return {
      visible: false,
      title: '',
      content: '',
    };
  },
  created() {
    this.$console.warn('deprecated component /src/components/FullContentModal.vue');
  },
  methods: {
    show({ content, title } = {}) {
      this.title = title || '';
      this.content = content || [];

      this.visible = true;
    },
    close() {
      this.visible = false;
    },
    afterClosed() {
      this.title = '';
      this.content = [];
    },
  },
};
</script>

<style scoped lang="scss">
.full-content {
  overflow-y: auto;
  max-height: 60vh;
}
</style>
