<template>
  <div class="pp-sticker-detail">
    <div class="row wrapper">
      <div class="col col-column left">
        <div class="row row-pic">
          <div class="col col-left">
            <span class="title">*上传图片：</span>
            <a-upload
              :multiple="false"
              accept=".jpg, .jpeg, .png"
              list-type="picture-card"
              :disabled="readOnly"
              :show-upload-list="false"
              :custom-request="customRequest"
              :beforeUpload="beforeUpload"
            >
              <img v-if="pic" :src="pic" alt="封面" class="cover-img"/>
              <div v-else>
                <a-icon :type="loading ? 'loading' : 'plus'"/>
                <div class="ant-upload-text">上传</div>
              </div>
            </a-upload>
          </div>
          <div class="input-hint">
            尺寸：558*292，不超过1M，仅支持片格式： .jpg .jpeg .png ，限上传一张
          </div>
        </div>

        <div class="row">
          <div class="col">
            <span class="title">跳转标题：</span>
            <a-input
              v-model="title"
              :disabled="readOnly"
              placeholder="跳转标题"
            ></a-input>
          </div>
        </div>

        <div class="row">
          <div class="col col-left">
            <span class="title">*跳转类型：</span>
            <a-radio-group
              v-model="jumpWay"
              name="jump_way"
              :disabled="readOnly"
            >
              <a-radio :value="0">不跳转</a-radio>
              <a-radio :value="1">H5</a-radio>
              <a-radio :value="2">小程序</a-radio>
              <a-radio :value="3">跳转App</a-radio>
            </a-radio-group>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <span class="title">*跳转链接：</span>
            <a-input
              v-model="url"
              :disabled="readOnly"
              placeholder="跳转链接"
            ></a-input>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <span class="title">*推荐值：</span>
            <a-input
              v-model="isNum"
              :disabled="readOnly"
              placeholder="推荐值"
            ></a-input>
          </div>
        </div>

        <div class="row">
          <div class="col col-left">
            <span class="title">*弹出次数：</span>
            <a-radio-group
              v-model="alertType"
              name="alert_type"
              :disabled="readOnly"
            >
              <a-radio :value="1">始终</a-radio>
              <a-radio :value="2">仅弹一次</a-radio>
            </a-radio-group>
          </div>
        </div>
      </div>

      <div class="col col-column right">
        <div class="row no-bottom-padding">
          <div class="col col-left">
            <span class="title">白名单：</span>
            <a-radio-group
              v-model="isWhite"
              name="is_white"
              :disabled="readOnly"
            >
              <a-radio :value="1">开启</a-radio>
              <a-radio :value="0">关闭</a-radio>
            </a-radio-group>
          </div>
        </div>

        <div class="row no-top-padding">
          <div class="col">
            <span class="title"></span>
            <a-input
              v-model="whiteUids"
              :disabled="readOnly"
              placeholder="白名单用户 ID"
            ></a-input>
          </div>
          <div class="input-hint">请填入用户 ID, 逗号分隔。默认携带 token</div>
        </div>

        <div class="row row-left">
          <div class="col col-left">
            <span class="title">*岗位：</span>
            <app-select-job v-model="postIds" :disabled="readOnly"/>
          </div>
        </div>

        <div class="row row-left">
          <div class="col col-left">
            <span class="title">*组织机构：</span>
            <app-select-org v-model="orgIds" :disabled="readOnly"/>
          </div>
        </div>

        <div class="row row-left">
          <div class="col col-left">
            <span class="title">上架时间：</span>
            <a-date-picker
              show-time
              v-model="shutUpTime"
              :disabled="readOnly"
              :disabled-date="disabledStartDate"
              :disabled-time="disabledStartTime"
            ></a-date-picker>
          </div>
        </div>

        <div class="row row-left">
          <div class="col col-left">
            <span class="title">下架时间：</span>
            <a-date-picker
              show-time
              v-model="shutDownTime"
              :disabled="readOnly"
              :disabled-date="disabledEndDate"
              :disabled-time="disabledEndTime"
            ></a-date-picker>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-30 ph-20">
      <div class="col col-left">
        <a-button :disabled="readOnly" type="primary" @click="save(false)"
        >保存
        </a-button
        >
        <a-button @click="cancel">取消</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import * as moment from 'moment';

import OssUploader, { getRandomString } from '@/utils/OssUploader';
import { getOSSInfo, uploadPic } from '@/api/Common';
import { saveSticker } from '../api/sticker';

export default {
  name: 'Detail',
  computed: {
    ...mapState({
      readOnly: (state) => state.Publicity.stickerReadOnly,
      sticker: (state) => state.Publicity.sticker,
    }),
  },
  watch: {
    'sticker.pic'(pic) {
      this.pic = pic || '';
    },
    'sticker.title'(title) {
      this.title = title || '';
    },
    'sticker.jump_way'(jumpWay) {
      this.jumpWay = jumpWay;
    },
    'sticker.url'(url) {
      this.url = url || '';
    },
    'sticker.is_num'(isNum) {
      this.isNum = isNum || 1;
    },
    'sticker.alert_type'(alertType) {
      this.alertType = alertType;
    },
    'sticker.is_white'(isWhite) {
      this.isWhite = isWhite;
    },
    'sticker.white_uids'(ids) {
      this.whiteUids = ids || '';
    },
    'sticker.org_arr'(orgs) {
      this.orgIds = orgs && orgs.length ? orgs.map((i) => i.id).join(',') : '';
    },
    'sticker.post_arr'(posts) {
      this.postIds =
        posts && posts.length ? posts.map((i) => i.id).join(',') : '';
    },
    'sticker.shutup_time'(timestamp) {
      if (timestamp) {
        this.shutUpTime = moment(timestamp * 1000);
      } else {
        this.shutDownTime = null;
      }
    },
    'sticker.shutdown_time'(timestamp) {
      if (timestamp) {
        this.shutDownTime = moment(timestamp * 1000);
      } else {
        this.shutDownTime = null;
      }
    },
  },
  data() {
    return {
      loading: false,

      titles: [{ title: '贴片配置' }, { title: '编辑贴片' }],

      title: '',
      pic: '',
      jumpWay: 0,
      url: '',
      isNum: 1,
      alertType: 1,
      isWhite: 1,
      whiteUids: '',
      orgIds: '',
      postIds: '',
      shutUpTime: null,
      shutDownTime: null,
    };
  },
  created() {
    this.id = this.$route.query?.id || null;
    this.$store.dispatch('Publicity/getSticker', this.id);
  },
  destroyed() {
    this.$store.commit('Publicity/updateStickerReadOnly', true);
    this.$store.commit('Publicity/updateStickerId', 0);
    this.$store.commit('Publicity/updateSticker', {});
  },
  methods: {
    async save() {
      if (this.readOnly || this.loading) {
        return;
      }

      if (!this.pic) {
        return this.$message.warning({ content: '请上传图片' });
      }
      if (!this.jumpWay && this.jumpWay !== 0) {
        return this.$message.warning({ content: '请选择跳转类型' });
      }
      if (!this.url) {
        return this.$message.warning({ content: '请填写跳转链接' });
      }
      if (!this.isNum && this.isNum !== 0) {
        return this.$message.warning({ content: '请填写推荐值' });
      }
      if (!this.orgIds) {
        return this.$message.warning({ content: '请选择组织机构' });
      }
      if (!this.postIds) {
        return this.$message.warning({ content: '请选择岗' });
      }
      if (this.jumpWay === 1 && this.url.indexOf('https://') !== 0) {
        return this.$message.warning({
          content: 'h5 链接必须以 https:// 开头',
        });
      }
      // if (!this.shutUpTime) {
      //   return this.$message.warning({content: '请选择上架时间'});
      // }
      // if (!this.shutDownTime) {
      //   return this.$message.warning({content: '请选择下架时间'});
      // }

      this.loading = true;

      const params = {
        type: 1,
        pic: this.pic,
        title: this.title,
        url: this.url,
        jump_way: this.jumpWay,
        alert_type: this.alertType,
        is_white: this.isWhite,
        white_uids: this.whiteUids || '',
        is_num: this.isNum,
        shutup_time: this.shutUpTime
          ? this.shutUpTime.format('YYYY-MM-DD HH:mm:ss')
          : '',
        shutdown_time: this.shutDownTime
          ? this.shutDownTime.format('YYYY-MM-DD HH:mm:ss')
          : '',
        org_ids: this.orgIds,
        post_ids: this.postIds,
      };
      this.id && (params.id = this.id);

      const data = await saveSticker(params).finally(
        () => (this.loading = false),
      );

      if (!data || data.error_code) {
        this.$message.error({ content: data?.message || '保存失败' });
        return;
      }
      this.id = data?.data?.id || null;
      this.$message.success({ content: data?.message || '保存成功' });
      this.cancel();
    },
    cancel() {
      this.$router.back();
    },

    disabledStartDate(date) {
      const now = new moment();
      if (!date) {
        return true;
      }
      if (this.shutDownTime) {
        return (
          date.isBefore(now, 'date') || date.isAfter(this.shutDownTime, 'date')
        );
      }
      return date.isBefore(now, 'date');
    },
    disabledStartTime(date) {
      if (!date) {
        return {
          disabledHours: () => this.range(0, 24),
          disabledMinutes: () => [0, 60],
          disabledSeconds: () => [0, 60],
        };
      }
      if (this.shutDownTime && date.isSame(this.shutDownTime, 'date')) {
        const endHour = this.shutDownTime.hour();
        const endMinute = date.isSame(this.shutDownTime, 'minute')
          ? this.shutDownTime.minute()
          : 0;
        if (date.isSame(this.shutDownTime, 'date')) {
          return {
            disabledHours: () => (endHour ? this.range(endHour, 24) : []),
            disabledMinutes: () => (endMinute ? this.range(endMinute, 60) : []),
            disabledSeconds: () => [],
          };
        }
      }

      const now = new moment();
      const hour = date.isSame(now, 'date') ? now.hour() : 0;
      const minute =
        date.isSame(now, 'date') && !date.isAfter(now, 'hour')
          ? now.minute()
          : 0;
      return {
        disabledHours: () => (hour ? this.range(0, hour) : []),
        disabledMinutes: () => (minute ? this.range(0, minute) : []),
        disabledSeconds: () => [],
      };
    },

    disabledEndDate(date) {
      const now = new moment();
      if (!date) {
        return true;
      }
      if (this.shutUpTime) {
        return (
          date.isBefore(now, 'date') || date.isBefore(this.shutUpTime, 'date')
        );
      }
      return date.isBefore(now, 'date');
    },
    disabledEndTime(date) {
      if (!date) {
        return {
          disabledHours: () => this.range(0, 24),
          disabledMinutes: () => [0, 60],
          disabledSeconds: () => [0, 60],
        };
      }
      const now = new moment();
      const beforeDate =
        this.shutUpTime && this.shutUpTime.isAfter(now) ? this.shutUpTime : now;

      const hour = date.isSame(beforeDate, 'date') ? beforeDate.hour() : 0;
      const minute =
        date.isSame(beforeDate, 'date') && !date.isAfter(beforeDate, 'hour')
          ? beforeDate.minute()
          : 0;
      return {
        disabledHours: () => (hour ? this.range(0, hour) : []),
        disabledMinutes: () => (minute ? this.range(0, minute) : []),
        disabledSeconds: () => [],
      };
    },
    range(start, end) {
      const result = [];
      for (let i = start; i < end; i++) {
        result.push(i);
      }
      return result;
    },

    beforeUpload(file) {
      const types = ['jpg', 'jpeg', 'png'];
      const type = file.type;
      const size = file.size;

      return new Promise((resolve, reject) => {
        if (types.every((t) => !type.includes(t))) {
          this.$message.info('图片仅支持 .jpg .jpeg .png 格式，请重新选择图片');
          return reject('ImageTypeError');
        }

        if (size > 1024 * 1024) {
          this.$message.info('图片大小不能超过 1M，请重新选择图片');
          return reject('ImageSizeError');
        }

        return resolve();

        // const imgUrl = URL.createObjectURL(file);
        // const imgEl = new Image();
        // imgEl.src = imgUrl;
        // imgEl.onload = () => {
        //   const rate = (558 / 292).toFixed(1);
        //   const imgRate = (imgEl.width / (imgEl.height || 1)).toFixed(1);
        //
        //   if (rate !== imgRate) {
        //     this.$message.info('图片支持尺寸为：558*292，请重新选择图片');
        //     return reject('ImageRateError');
        //   }
        //
        //   return resolve();
        // }
      });
    },

    customRequest({ file }) {
      // this.uploadPic(file, this.getFileName(file.name));
      this.uploadPicOvert(file);
    },

    getFileName(name) {
      const idx = name.lastIndexOf('.');
      const type = idx > -1 ? name.substr(idx) : 'jpg';
      return getRandomString() + type;
    },

    /**
     * 上传音频文件至 OSS
     */
    async uploadPic(data, fileName) {
      this.uploadingPic = true;
      let host = '';
      let imgUrl = '';
      let key = 'advertising';

      return new Promise((resolve, reject) => {
        new OssUploader({
          init: async (up) => {
            const oss = await this.getOssInfo(key);
            host = oss.host;
            imgUrl = oss.img_url;
            // eslint-disable-next-line require-atomic-updates
            key = oss.dir + fileName;
            const now = Date.parse(new Date()) / 1000;
            const expire = parseInt(oss.expire);
            if (expire < now + 3) {
              console.log('oss 过期了', new Date(expire * 1000));
            }
            console.log('oss 没过期');

            const multipartParams = {
              key: key,
              policy: oss.policy,
              OSSAccessKeyId: oss.accessid,
              success_action_status: '200', // 让服务端返回200,不然，默认会返回204
              signature: oss.signature,
            };

            up.setOption({
              url: host,
              multipart_params: multipartParams,
            });

            up.addFile(data, fileName);

            up.start();
          },
          finish: (up, file, info) => {
            if (info.status === 200) {
              console.log('[FileUploaded] success', file, info);
              this.pic = imgUrl + '/' + key;
            } else if (info.status === 203) {
              this.uploadingPic = false;
              console.log(
                '[FileUploaded] 上传到OSS成功，但是oss访问用户设置的上传回调服务器失败，失败原因是:' +
                info.response,
              );
            } else {
              this.uploadingPic = false;
            }
            resolve();
          },
          error: (err) => {
            this.uploadingPic = false;
            reject(err);
          },
        });
      });
    },

    /**
     * 图片上传至后台
     */
    async uploadPicOvert(file) {
      const formData = new FormData();
      formData.append('file', file);

      const data = await uploadPic(formData);
      this.pic = data?.data?.url || '';
    },

    /**
     * 获取 OSS 信息
     */
    async getOssInfo(key) {
      const data = await getOSSInfo({ dir: key }).catch(
        () => (this.uploadingPic = false),
      );
      if (!data) {
        this.uploadingPic = false;
        this.$message.error({ message: '上传图片失败' });
        return Promise.reject(data);
      }

      return Promise.resolve(data);
    },
  },
};
</script>

<style scoped lang="scss">
.pp-sticker-detail {
  width: 100%;

  .row-pic {
    height: 150px;
  }

  .cover-img {
    max-width: 300px;
    height: 104px;
  }

  .row {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .ph-20 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .wrapper {
    justify-content: flex-start;
    align-items: flex-start;
  }

  .left {
    margin-right: 50px;

    .col {
      min-width: 240px;
    }
  }

  .left,
  .right {
    .row {
      position: relative;
    }

    .col {
      min-height: 50px;
    }
  }

  .input-hint {
    position: absolute;
    left: 105px;
    bottom: 0;
    font-size: 12px;
    color: $light02;
  }

  .title {
    width: 100px;
    min-width: 100px;
    max-width: 100px;
    text-align: right;
  }

  .extra {
    margin-left: 10px;
  }
}
</style>
